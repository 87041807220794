<template>
  <div class="container content">
    <div class="headline">
      <h1>{{ $t('message.company.header') }}</h1>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent is-8">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.company.show.header') }}</p>
          <div class="columns">
           <div class="column is-half">
               <b-field :label="$t('message.company.show.name')">
                 {{ company.name }}
              </b-field>
              <!--b-field :label="$t('message.company.show.description')">
                {{ company.description }}
              </b-field-->
              <b-field :label="$t('message.company.show.email')">
                {{ company.email }}
              </b-field>
              <b-field :label="$t('message.company.show.mobile')">
                {{ company.mobile }}
              </b-field>
              <b-field :label="$t('message.company.show.address')">
                {{ company.address }}
              </b-field>
              <b-field :label="$t('message.company.show.zip')">
                {{ company.zip }}
              </b-field>
              <b-field :label="$t('message.company.show.city')">
                {{ company.city }}
              </b-field>
            </div>
            <div class="column is-half">
              <b-field :label="$t('message.company.show.vat')">
                {{ company.vat }}
              </b-field>
              <b-field :label="$t('message.company.show.www')">
                {{ company.www }}
              </b-field>
             </div>
            </div>

            <b-button
              :loading="false"
              :label="$t('message.company.edit.button')"
              size="is-large"
              class="fullwidth"
              type="is-success" 
              v-on:click="edit" />

        </article>
     </div>
    </div>  
  </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    methods: {
        edit() {
          this.$router.push('/muokkaa-yritys/' + this.company.id)
        },
    },
    computed: {
        user() {
            return this.$store.getters['getUser']
        }, 
        company() {
          var id = this.user['company_id']
          return this.$store.getters['getCompanies'].find(p => p.id == id)
        },
    },
    mounted() {
       this.$store.dispatch("fetchCompanies");
    }
}
</script>